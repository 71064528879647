<template>
  <v-container fluid class="dashboard-page">
    <v-row>
      <v-col cols="auto" md="12" sm="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.drivingCountToday') }}</span>
                <span class="d-block">{{ mainBannerData?.drivingCountToday }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.drivingCountThisMonth') }}</span>
                <span class="d-block">{{ mainBannerData?.drivingCountThisMonth }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.dailyRentsAndTransfers') }}</span>
                <span class="d-block">
                  {{ mainBannerData?.drivingTypeDailyRentCount + '/' + mainBannerData?.drivingTypeTransfersCount }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.drivingTotalAmount') }}</span>
                <span class="d-block">{{ mainBannerData?.drivingTotalAmount }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.driversCount') }}</span>
                <span class="d-block">{{ mainBannerData?.driversCount }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.passengerCount') }}</span>
                <span class="d-block">{{ mainBannerData?.passengerCount }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div id="dashboard-step-0"></div>
      </v-col>

      <v-col cols="12" md="6" lg="4" xl="6">
        <div :id="window.width < 600 ? 'dashboard-step-1' : ''"></div>
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.drivingsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="drivingInNext3DaysHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivingInNext3Days"
          class="elevation-1 cursor-pointer"
          :items-per-page="10"
          @click:row="showEvent({ nativeEvent: false, event: item })"
          multi-sort>
          <template v-slot:item.price="{ item }">
            {{ item.currency ? item?.currency?.symbol + item?.price : item?.price }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.inNext3Days') }}
                </v-toolbar-title>
                <p :id="window.width > 599 ? 'dashboard-step-1' : ''" class="first-step"></p>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <tr v-bind="attrs" v-on="on" @click="showEvent({ nativeEvent: false, event: item })">
                  <td>{{ item.number }}</td>
                  <td>{{ item.currency ? item?.currency?.symbol + item?.price : item?.price }}</td>
                  <td>{{ item.from_location_name }}</td>
                  <td>{{ item.vehicle?.name }}</td>
                </tr>
              </template>

              <driving-info-tooltip :item="item" :drivers="drivers" />
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="6" lg="4" xl="3">
        <div :id="window.width < 600 ? 'dashboard-step-2' : ''"></div>
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.drivingsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="drivingNotPaidOrConfirmedHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivingNotPaidOrConfirmed"
          class="elevation-1 cursor-pointer"
          :items-per-page="10"
          @click:row="openDrivshowEvent({ nativeEvent: false, event: item })"
          multi-sort>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.notConfirmedOrPaid') }}
                </v-toolbar-title>

                <p :id="window.width > 599 ? 'dashboard-step-2' : ''" class="first-step"></p>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <tr v-bind="attrs" v-on="on" @click="showEvent({ nativeEvent: false, event: item })">
                  <td>{{ item.number }}</td>
                  <td>
                    <v-icon>{{ parseInt(item.paid) ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  </td>
                  <td>
                    <v-icon>{{ parseInt(item.client_confirmed) ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  </td>
                </tr>
              </template>

              <driving-info-tooltip :item="item" :drivers="drivers" />
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="12" lg="4" xl="3">
        <v-row>
          <v-col cols="12" md="6" lg="12">
            <div :id="window.width < 600 ? 'dashboard-step-3' : ''"></div>
            <v-data-table
              :footer-props="{
                pageText: `{0}-{1} ${$t('tables.of')} {2}`,
                itemsPerPageText: $t('tables.driversByPage'),
                itemsPerPageOptions: [5, 10, 20, 50, 100],
              }"
              :headers="driversInNext5DaysHeaders"
              :no-data-text="$t('tables.noDataText')"
              :no-results-text="$t('tables.noDataText')"
              :items="driversInNext5Days"
              class="elevation-1 cursor-pointer"
              :items-per-page="5"
              @click:row="openDrivingWithDriver"
              multi-sort>
              <template v-slot:item.full_name="{ item }">
                {{ item.first_name + ' ' + item.last_name }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <template>
                    <v-toolbar-title>
                      {{ $t('tables.inNext5Days') }}
                    </v-toolbar-title>

                    <p :id="window.width > 599 ? 'dashboard-step-3' : ''" class="first-step"></p>
                  </template>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" md="6" lg="12">
            <div :id="window.width < 600 ? 'dashboard-step-4' : ''"></div>
            <v-data-table
              :footer-props="{
                pageText: `{0}-{1} ${$t('tables.of')} {2}`,
                itemsPerPageText: $t('tables.reviewsByPage'),
                itemsPerPageOptions: [5, 10, 20, 50, 100],
              }"
              :headers="passengerReviewsLast5Headers"
              :no-data-text="$t('tables.noDataText')"
              :no-results-text="$t('tables.noDataText')"
              :items="passengerReviewsLast5"
              class="elevation-1"
              :items-per-page="5"
              multi-sort
              hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <template>
                    <v-toolbar-title>
                      {{ $t('tables.latestReviews') }}
                    </v-toolbar-title>

                    <p :id="window.width > 599 ? 'dashboard-step-4' : ''" class="first-step"></p>
                  </template>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour name="dashboardTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>

    <driving-modal :selectedElement="selectedElement" />
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import router from '@/router';
import { defaultDriving, defaultDashboardBannerData } from '@/mixins/default-items';
import DrivingModal from './drivings/DrivingModal.vue';
import {
  drivingInNext3DaysHeaders,
  drivingNotPaidOrConfirmedHeaders,
  driversInNext5DaysHeaders,
  passengerReviewsLast5Headers,
} from '@/mixins/data-table-headers';
import { formatDateTime } from '@/utils/formatDate';
import { changeColorBasedOnProposalStatus } from '@/utils/changeColorBasedOnProposalStatus';
import DrivingInfoTooltip from '@/components/drivings/DrivingInfoTooltip.vue';
import { getAllTourSteps, dashboardSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';

export default {
  name: 'DashboardPage',
  props: [],
  components: { DrivingModal, DrivingInfoTooltip },
  data() {
    return {
      mainBannerData: {},
      drivingInNext3Days: [],
      drivingNotPaidOrConfirmed: [],
      driversInNext5Days: [],
      passengerReviewsLast5: [],
      selectedElement: null,
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: false,
      },
    };
  },
  created() {
    this.selectedElement = Object.assign({}, defaultDriving);
    this.mainBannerData = Object.assign({}, defaultDashboardBannerData);
    this.getDashboardData();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    drivingInNext3DaysHeaders() {
      return drivingInNext3DaysHeaders(i18n);
    },
    drivingNotPaidOrConfirmedHeaders() {
      return drivingNotPaidOrConfirmedHeaders(i18n);
    },
    driversInNext5DaysHeaders() {
      return driversInNext5DaysHeaders(i18n);
    },
    passengerReviewsLast5Headers() {
      return passengerReviewsLast5Headers(i18n);
    },
    drivers() {
      return this.$store.getters['users/getDrivers'].map((item) => {
        return {
          ...item,
          full_name: item.profile?.full_name || 'N/A',
        };
      });
    },
  },
  methods: {
    async getDashboardData() {
      await this.$store.dispatch('dashboard/getDashboardData').then((res) => {
        this.mainBannerData = res.data.mainBannerData;
        this.drivingInNext3Days = res.data.drivingInNext3Days.map((item) => {
          item.distance = parseFloat(item.distance);
          item.price = parseFloat(item.price);
          item.paid = parseInt(item.paid);
          item.from_location ? (item.from_location_name = JSON.parse(item.from_location).name) : '';
          item.to_location ? (item.to_location_name = JSON.parse(item.to_location).name) : '';
          let proposalStatus = item?.drivingProposal?.drivingProposalStatus?.name;
          item.color = changeColorBasedOnProposalStatus(proposalStatus);
          // we keep original value of pickupt time for comparing date
          item.pickup_time_original = item.pickup_time;
          // we keep format pickup time for preview
          item.pickup_time = formatDateTime(item.pickup_time);
          item.expected_drop_off_time = formatDateTime(item.expected_drop_off_time);
          item.expected_comeback_time = formatDateTime(item.expected_comeback_time);
          item.created_at = formatDateTime(item.created_at);
          item.driving_number = item.number + ` [${item.public_number}]`;

          return item;
        });
        this.drivingNotPaidOrConfirmed = res.data.drivingNotPaidOrConfirmed.map((item) => {
          item.distance = parseFloat(item.distance);
          item.price = parseFloat(item.price);
          item.paid = parseInt(item.paid);
          item.from_location ? (item.from_location_name = JSON.parse(item.from_location).name) : '';
          item.to_location ? (item.to_location_name = JSON.parse(item.to_location).name) : '';
          let proposalStatus = item?.drivingProposal?.drivingProposalStatus?.name;
          item.color = changeColorBasedOnProposalStatus(proposalStatus);
          // we keep original value of pickupt time for comparing date
          item.pickup_time_original = item.pickup_time;
          // we keep format pickup time for preview
          item.pickup_time = formatDateTime(item.pickup_time);
          item.expected_drop_off_time = formatDateTime(item.expected_drop_off_time);
          item.expected_comeback_time = formatDateTime(item.expected_comeback_time);
          item.created_at = formatDateTime(item.created_at);
          item.driving_number = item.number + ` [${item.public_number}]`;

          return item;
        });
        this.driversInNext5Days = res.data.driversInNext5Days;
        this.passengerReviewsLast5 = res.data.passengerReviewsLast5;
      });

      this.setStepsAndStartTour();
    },
    showEvent({ nativeEvent, event }) {
      this.selectedElement = Object.assign({}, event);

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    openDrivingWithDriver(item) {
      // Resolve the URL from the router
      const routeData = router.resolve({
        name: 'TabularView',
        query: { driver_id: item.id },
      });

      // Open a new tab with the resolved URL
      window.open(routeData.href, '_blank');
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '12')
            ?.map((item) => item.step_id);
          this.steps = dashboardSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['dashboardTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '12',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '12',
        step_id: `#dashboard-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '12',
            step_id: `#dashboard-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    '$store.state.addedNewDrivingCounter': {
      immediate: false,
      handler() {
        this.getDashboardData();
      },
    },
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.dashboard-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
